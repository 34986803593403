import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import ContentBlock from "../components/ContentBlock"
import SubTitle from "../components/SubTitle"
import UsedTech from "../components/UsedTech"

import "../components/Layout.css"

import FitsfulMainBgImage from "../images/fitsful-main-bg.svg"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  aboutTitle,
  aboutDescription,
  optionsTitle,
  optionsDescription,
  usersTitle,
  usersDescription,
  advancedTitle,
  advancedDescription,
  listImage,
  eventsImage,
  optionsImage,
  usersImage,
  advancedImage,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative md:flex container mx-auto py-10 md:py-24 pb-12 px-10 lg:px-32">
        <img
          src={FitsfulMainBgImage}
          alt=""
          className="hidden md:block absolute w-120 z-10"
          style={{ right: "20%", top: "70px" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3 mt-10 lg:mt-0 sm:w-3/4 items-center z-20">
          {listImage && (
            <img
              src={listImage.childImageSharp.fixed.src}
              alt=""
              className="mx-auto w-3/4 md:-mt-20"
            />
          )}
        </div>
      </div>

      {/* ABOUT BLOCK*/}
      <div
        className="bg-gray-300 relative overflow-x-hidden overflow-y-hidden"
        style={{
          backgroundImage: "linear-gradient(to bottom, #F9F9F9, #E7E6E6)",
        }}
      >
        <ContentBlock className="md:pb-120">
          <div className="md:w-1/2 md:pt-10">
            <SubTitle>{aboutTitle}</SubTitle>
            <p>{aboutDescription}</p>
          </div>
        </ContentBlock>
        <img
          src={eventsImage.childImageSharp.fixed.src}
          alt=""
          className="self-center md:absolute md:-bottom-20 md:-right-40"
        />
      </div>

      {/* OPTIONS AND USERS BLOCK*/}
      <ContentBlock>
        <div className="md:grid md:grid-cols-2 gap-20 md:pt-10">
          <div>
            <img
              src={optionsImage.childImageSharp.fixed.src}
              alt=""
              className="md:relative bottom-0 right-0"
            />
          </div>
          <div className="flex items-center">
            <div>
              <SubTitle>{optionsTitle}</SubTitle>
              <p>{optionsDescription}</p>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <SubTitle>{usersTitle}</SubTitle>
              <p>{usersDescription}</p>
            </div>
          </div>
          <div className="">
            <img
              src={usersImage.childImageSharp.fixed.src}
              alt=""
              className="md:relative bottom-0 right-0"
            />
          </div>
        </div>
      </ContentBlock>

      {/* ADVANCED BLOCK*/}
      <div className="bg-gray-300">
        <ContentBlock>
          <div>
            <SubTitle bulletPoint>{advancedTitle}</SubTitle>
            <div className="md:flex md:flex-row">
              <div className="md:w-1/2 mb-10 md:px-20 md:py-10 md:pl-0">
                <img
                  src={advancedImage.childImageSharp.fixed.src}
                  alt=""
                  className="md:relative bottom-0 right-0"
                />
              </div>
              <div className="md:w-1/2 md:flex items-center">
                <p>{advancedDescription}</p>
              </div>
            </div>
          </div>
        </ContentBlock>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <UsedTech title={title} AppDev SoftDev UiUx />
    </div>
  )
}

const Fitsful = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={project.html}
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default Fitsful

export const pageQuery = graphql`
  query FitsfulID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        aboutTitle
        aboutDescription
        optionsTitle
        optionsDescription
        usersTitle
        usersDescription
        advancedTitle
        advancedDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        eventsImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        optionsImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usersImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        advancedImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
